import type { Element } from './Pagination'
import { Link } from '../../atoms/Link'

interface IPaginationLink {
  elem: Element
}

export function PaginationLink({ elem }: IPaginationLink): JSX.Element {
  return elem.hasActive ? (
    <span aria-current="page" aria-label={`Page ${elem.page}`}>
      {elem.page}
    </span>
  ) : (
    <Link url={elem.link} aria-label={`Page ${elem.page}`}>
      {elem.page}
    </Link>
  )
}
